<template>
  <div>
    <b-container fluid class="content">
      <b-alert
        :show="countdown"
        dismissible
        variant="success"
        @dismissed="countdown = 0"
        @dismiss-count-down="close_alert"
        class="show_alert"
      >
        {{ dismessage }}
      </b-alert>
      <b-row class="mt-4" v-if="currpage == 'list'">
        <b-col>
          <b-card>
            <b-row>
              <b-col>
                <el-button type="primary" @click="add_modifier" v-acl:ModifierEdit.enable.show>
                  {{ $t("Add") }}
                </el-button>
                <el-button type="primary" @click="enableSort" v-if="!isSortableEnabled && hasUtilityAuth('ModifierEdit')">
                  {{ $t("Sort Order") }}
                </el-button>
                <el-button type="primary" @click="disableSort" v-if="isSortableEnabled && hasUtilityAuth('ModifierEdit')">
                  {{ $t("Save Change") }}
                </el-button>
                <span v-if="isSortableEnabled" class="ml-1"> {{ $t('Please drag and drop the table.') }} </span>
              </b-col>
            </b-row>
            <p class="p-3" v-if="modifierlist">
              <span>{{ "*" + $t('Note') +": " + $t('Only Ingredient type can be set as Online.') }}</span>
              <el-table
                :class="{handshape:isSortableEnabled}"
                class="table-responsive table"
                header-cell-class-name="my-header"
                :data="modifierlist"
                table-layout="auto"
                ref="dragTable"
                row-key="IDn"
                style="width: 100%;"
              >
                <el-table-column :label="$t('Online')" width="100" align="center">
                  <template v-slot="{ row }">
                    <el-checkbox v-model="row.Online" v-if="row.Type=='Ingredient'" @change="change_online(row)" true-label=true false-label=false v-acl:ModifierEdit.enable></el-checkbox>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('Name') + '1'" prop="Name1">
                </el-table-column>
                <el-table-column :label="$t('Name') + '2'" prop="Name2">
                </el-table-column>
                <el-table-column :label="$t('Min')" prop="MinSelectedItems">
                </el-table-column>
                <el-table-column :label="$t('Max')" prop="MaxSelectedItems">
                </el-table-column>
                <el-table-column :label="$t('Auto pop-up')" prop="AutoPopUp">
                  <template v-slot="{ row }">
                    <el-switch
                      v-model="row.AutoPopUp"
                      inline-prompt
                      active-value="1"
                      inactive-value="0"
                      :active-text="$t('Yes')"
                      :inactive-text="$t('No')"
                      @change="change_autoPopUp(row)"
                    />
                  </template>
                </el-table-column>
                <el-table-column :label="$t('Type')" prop="Type">
                </el-table-column>
                <el-table-column :label="$t('Status')" prop="Status">
                  <template v-slot="{ row }">
                    <div v-if="row.Status == 1" style="color: blue">{{ $t("Active") }}</div>
                    <div v-else style="color: red">{{ $t("Inactive") }}</div>
                  </template>
                </el-table-column>
                <el-table-column
                  :label="$t('Action')"
                  align="center"
                  width="270"
                >
                  <template slot-scope="scope">
                    <el-button-group>
                      <el-button
                        icon="el-icon-edit"
                        size="medium"
                        plain
                        @click="edit_modifier(scope.row)"
                        v-acl:ModifierEdit.enable.show
                      >
                        {{ $t('Edit') }}
                      </el-button>
                      <el-button 
                        icon="el-icon-delete"
                        size="medium"
                        type="danger"
                        plain
                        :disabled="scope.row.RobotKey !== '' && !isSuper"
                        @click="delete_modifier(scope.row)"
                        v-acl:ModifierEdit.enable.show
                      >
                        {{ $t('Delete') }}
                      </el-button>
                    </el-button-group>
                  </template>
                </el-table-column>
              </el-table>
            </p>
            <p class="p-3" v-else>
              {{ $t("No Records") }}
            </p>
          </b-card>
        </b-col>
      </b-row>

      <b-row class="mt-0" v-if="currpage == 'edit'">
        <b-col>
          <b-card 
            border-variant="light"
            header-bg-variant="secondary"
            header-tag="header"
          >
            <template #header>
              <b-row>
                <b-col cols="6">
                  <h2 class="mb-0" style="line-height: 2.5rem !important;">{{ $t('Edit Modifier') }}</h2>
                </b-col>
                <b-col cols="6" class="text-right">
                  <el-button type="primary" @click="form_cancel">
                    {{ $t("Back") }}
                  </el-button>
                  <el-button type="success" @click="form_submit">
                    {{ $t("Submit") }}
                  </el-button>
                </b-col>
              </b-row>
            </template>
            <b-form>
              <b-list-group flush>
                <b-list-group-item>
                  <b-row v-if="isSuper">
                    <b-col cols="3" class="text-right">
                      <label class="mt-2 mr-sm-2" for="name1_input"><span style="color: red; font-weight: 500;">*</span>{{ 'Robot Key:' }}</label>
                    </b-col>
                    <b-col cols="9">
                      <b-form-group
                        :description='$t("Please enter the robot key of your modifier.")'
                      >
                        <b-form-input
                          v-model="form_modifier_group.RobotKey"
                          id="robotkey_input"
                          placeholder="Robot Key"
                          :disabled="form_modifier_group.RobotKey!=='' && !isSuper"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="3" class="text-right">
                      <label class="mt-2 mr-sm-2" for="name1_input"><span style="color: red; font-weight: 500;">*</span>{{ $t('Name') + '1:'}}</label>
                    </b-col>
                    <b-col cols="9">
                      <b-form-group
                        :description='$t("Please enter the Name 1 of your modifier.")'
                      >
                        <b-form-input
                          v-model="form_modifier_group.Name1"
                          id="name1_input"
                          :placeholder="$t('Name') + '1'"
                          required
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="3" class="text-right">
                      <label class="mt-2 mr-sm-2" for="name2_input">{{ $t('Name') + '2:'}}</label>
                    </b-col>
                    <b-col cols="9">
                      <b-form-group
                        :description='$t("Please enter the Name 2 of your modifier.")'
                      >
                        <b-form-input
                          v-model="form_modifier_group.Name2"
                          id="name2_input"
                          :placeholder="$t('Name') + '2'"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-list-group-item>
                <b-list-group-item>
                  <b-row>
                    <b-col cols="3" class="text-right">
                      <label class="mt-2 mr-sm-2" for="type_radio"><span style="color: red; font-weight: 500;">*</span>{{ $t('Type') }}</label>
                    </b-col>
                    <b-col cols="9">
                      <b-form-group
                        :description="form_modifier_group.IDn != ''? '' : $t('Please select the type of your modifier.')"
                      >
                        <b-form-radio-group
                          id="type_radio"
                          v-model="form_modifier_group.Type"
                          :options="type_options"
                          button-variant="outline-primary"
                          size="sm"
                          name="radio-options"
                          :disabled="form_modifier_group.IDn != ''"
                          @change="filterByType"
                        ></b-form-radio-group>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-list-group-item>
                <b-list-group-item>
                  <b-row>
                    <b-col cols="3" class="text-right">
                      <label class="mt-2 mr-sm-2" for="minQty_input"><span style="color: red; font-weight: 500;">*</span>{{ $t('Min') }}</label>
                    </b-col>
                    <b-col cols="3">
                      <b-form-group
                        :description='$t("Please enter the minimum quantity of your modifier.")'
                      >
                        <b-form-input
                          v-model="form_modifier_group.MinSelectedItems"
                          id="minQty_input"
                          :placeholder="$t('Min')"
                          :disabled="form_modifier_group.RobotKey!=='' && !isSuper"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col cols="1" class="text-right">
                      <label class="mt-2 mr-sm-2" for="maxQty_input"><span style="color: red; font-weight: 500;">*</span>{{ $t('Max') }}</label>
                    </b-col>
                    <b-col cols="3">
                      <b-form-group
                        :description='$t("Please enter the maximum quantity of your modifier.")'
                      >
                        <b-form-input
                          v-model="form_modifier_group.MaxSelectedItems"
                          id="maxQty_input"
                          :placeholder="$t('Max')"
                          :disabled="form_modifier_group.RobotKey!=='' && !isSuper"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-list-group-item>
                <b-list-group-item>
                  <b-row>
                    <b-col cols="3" class="text-right">
                      <label class="mt-2 mr-sm-2" for="autoPopUp_radio"><span style="color: red; font-weight: 500;">*</span>{{ $t('Auto pop-up') }}</label>
                    </b-col>
                    <b-col cols="9">
                      <b-form-group
                        :description='$t("Please choose if the modifier selection screen should automatically be pop-up.")'
                      >
                        <b-form-radio-group
                          id="autoPopUp_radio"
                          v-model="form_modifier_group.AutoPopUp"
                          :options="popup_options"
                          button-variant="outline-primary"
                          size="sm"
                          name="radio-options3"
                          :disabled="form_modifier_group.RobotKey!=='' && !isSuper"
                        ></b-form-radio-group>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-list-group-item>
                <b-list-group-item v-if="form_modifier_group.Type=='Combo'">
                  <b-row>
                    <b-col cols="3" class="text-right">
                      <label class="mt-2 mr-sm-2" for="printFromat"><span style="color: red; font-weight: 500;">*</span>{{ $t('Combo Print Mode') }}</label>
                    </b-col>
                    <b-col cols="9">
                      <b-form-group
                        :description='$t("Please choose Combo Print Mode")'
                      >
                        <b-form-radio-group
                          id="printFromat"
                          v-model="form_modifier_group.PrintMode"
                          :options="print_mode_options"
                          button-variant="outline-primary"
                          size="sm"
                          name="radio-options4"
                        ></b-form-radio-group>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-list-group-item>
                <b-list-group-item>
                  <b-row>
                    <b-col cols="3" class="text-right">
                      <label class="mt-2 mr-sm-2" for="type_radio2"><span style="color: red; font-weight: 500;">*</span>{{ $t('Status') }}</label>
                    </b-col>
                    <b-col cols="9">
                      <b-form-group
                        :description='$t("Please choose the status of your modifier.")'
                      >
                        <b-form-radio-group
                          id="type_radio2"
                          v-model="form_modifier_group.Status"
                          :options="status_options"
                          button-variant="outline-primary"
                          size="sm"
                          name="radio-options2"
                          :disabled="form_modifier_group.RobotKey!=='' && !isSuper"
                        ></b-form-radio-group>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-list-group-item>
                <b-list-group-item>
                  <b-card
                    border-variant="light"
                    header-tag="header"
                  >
                    <template #header>
                      <h3>{{ $t('Modifier Items') }}</h3>
                    </template>
                    <el-table
                      header-cell-class-name="my-header2"
                      :data="form_modifier_group.Items"
                      border
                      row-key="RandomKey"
                      ref="dragTable2"
                    >
                    <!-- RandomKey is used to prevent Vue from re-rendering the table, after SortableJS renders the table -->
                    <!-- row-key must be an unique value, but none of the prop in form_modifier_group.Items is unique -->
                    <!-- IDn: is NULL for newly added items -->
                    <!-- Name1: is NULL for newly added items -->
                    <!-- SortOrder: is 999 for newly added items -->
                      <el-table-column :label="$t('Sort')" width="75">
                        <span class="handle"></span>       
                      </el-table-column>
                      <el-table-column v-if="form_modifier_group.RobotKey !== ''" label="Levels" width="140">
                        <template #default="scope">
                          <el-input v-model="scope.row.RobotLv" :placeholder="'eg. 0, 1, 2, 3'" :disabled="form_modifier_group.RobotKey!=='' && !isSuper" style="width: 90% !important; margin: auto">
                              <template #prepend>{{ 'Lv' }}</template>
                          </el-input>
                        </template>
                      </el-table-column>
                      <el-table-column>
                        <template #header>
                          <div>
                            {{$t('Name')}}
                          </div>
                        </template>
                        <template #default="scope">
                          <b-row>
                            <el-input v-model="scope.row.Name1" :placeholder="$t('Name')+'1'" style="width: 90% !important; margin: auto">
                              <template #prepend>{{ $t('Name') + '1' }}</template>
                            </el-input>
                          </b-row>
                          <b-row>
                            <el-input v-model="scope.row.Name2" :placeholder="$t('Name')+'2'" style="width: 90% !important; margin: auto">
                              <template #prepend>{{ $t('Name') + '2' }}</template>
                            </el-input>
                          </b-row>       
                        </template>
                      </el-table-column>
                      <el-table-column :label="$t('Price')">
                        <template #default="scope">
                          <el-input
                            v-model="scope.row.Price"
                            :placeholder="$t('Price')"
                          >
                            <template #prepend>{{ "$ " }}</template>
                          </el-input>
                        </template>
                      </el-table-column>
                      <el-table-column fixed="right" label="Operations" width="120">
                        <template #default="scope">
                          <el-button
                            type="danger"
                            size="small"
                            icon="el-icon-close"
                            style="width: 100%"
                            :disabled="form_modifier_group.RobotKey!=='' && !isSuper"
                            @click.prevent="deleteRow(scope.$index)"
                          >
                            {{ $t('Remove') }}
                          </el-button>
                        </template>
                      </el-table-column>
                    </el-table>
                    
                    
                    <el-button
                      v-if="form_modifier_group.Type=='Combo'"
                      class="mt-4"
                      icon="el-icon-search"
                      type="warning"
                      style="width: 100%; display: inline-block; "
                      @click.prevent="confirmDialog2=true"
                    >
                      {{ $t('Search') }}
                    </el-button>
                    <el-button
                      v-else
                      class="mt-4"
                      icon="el-icon-plus"
                      type="primary"
                      :disabled="form_modifier_group.RobotKey!=='' && !isSuper"
                      style="width: 100%; display: inline-block; "
                      @click="addRow"
                    >
                      {{ $t('Add Item') }}
                    </el-button>
                  </b-card>
                </b-list-group-item>
              </b-list-group>
            </b-form>
          </b-card>
          <b-row>
            <b-col cols="12" class="text-right pt-3 pb-3 pr-6">
              <el-button type="primary" @click="form_cancel">
                {{ $t("Back") }}
              </el-button>
              <el-button type="success" @click="form_submit">
                {{ $t("Submit") }}
              </el-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <el-dialog
        :title="$t('Please Confirm Delete')"
        :visible.sync="confirmDialog"
        width="30%"
        center>
        <h1 class="text-danger text-center">{{ $t('Deletion cannot be undone after you confirm') }}</h1>
        <span slot="footer" class="dialog-footer">
          <el-button @click="confirmDialog = false" class="mr-6">{{ $t('Cancel') }}</el-button>
          <el-button type="primary" @click="deleteConfirmed" class="ml-6">{{ $t('Confirm') }}</el-button>
        </span>
      </el-dialog>
      <el-dialog
        :title="$t('Combo Modifier') + ': ' + $t('Select Items')"
        :visible.sync="confirmDialog2"
        width="50%"
        center
      >
        <b-form>
          <b-row>
            <b-col>
              <base-input :label="$t('Department')">
                <select
                  class="form-control"
                  v-model="selected_departmentIDn"
                  @change="get_category_list"
                >
                  <option
                    v-for="(dpt, idx) in departments"
                    :key="idx"
                    :value="dpt.IDn"
                  >
                    {{ dpt.Name1 }}-{{ dpt.Name2 }}
                  </option>
                </select>
              </base-input>
            </b-col>
          </b-row>
          <b-row v-if="selected_departmentIDn">
            <b-col>
              <base-input :label="$t('Category')">
                <select
                  class="form-control"
                  v-model="selected_categoryIDn"
                  @change="get_product_list"
                >
                  <option
                    v-for="(cat, idx) in categorys"
                    :key="idx"
                    :value="cat.IDn"
                  >
                    {{ cat.Name1 }}-{{ cat.Name2 }}
                  </option>
                </select>
              </base-input>
            </b-col>
          </b-row>
          <b-row v-if="selected_categoryIDn">
            <b-col>
              <base-input :label="$t('Product')">
                <b-form-group
                  v-slot="{ ariaDescribedby }"
                >
                  <b-form-checkbox-group
                    v-model="selected_productIDn"
                    :options="product_options"
                    :aria-describedby="ariaDescribedby"
                    stacked
                    buttons
                    button-variant="secondary"
                    style="width: 100%"
                  ></b-form-checkbox-group>
                </b-form-group>
              </base-input>
            </b-col>
          </b-row>
        </b-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="confirmDialog2 = false" class="mr-6">{{ $t('Cancel') }}</el-button>
          <el-button type="primary" @click="confirmProductSelection" class="ml-6">{{ $t('Confirm') }}</el-button>
        </span>
      </el-dialog>
    </b-container>
  </div>
</template>
<script>
import { Table, TableColumn, Button, Row, Col, Dropdown, Dialog, Checkbox, CheckboxButton, CheckboxGroup, ButtonGroup, Empty, Switch} from "element-ui";
import { getStore, setStore } from "../../util/helper";
import Sortable from "sortablejs";
import { isSuper, hasUtilityAuth } from "../../directives/acl";
// import ElTableDraggable from '../../../node_modules/el-table-draggable';

const axios = require("axios");

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Row.name]: Row,
    [Col.name]: Col,
    [Dropdown.name]: Dropdown,
    [Dialog.name]: Dialog,
    [Checkbox.name]: Checkbox,
    [CheckboxButton.name]: CheckboxButton,
    [CheckboxGroup.name]: CheckboxGroup,
    [ButtonGroup.name]: ButtonGroup,
    [Switch.name]: Switch,
  },
  data() {
    return {
      product_options: [],
      currpage: "list",
      cur_modifier: null,
      cur_opt: null,
      new_modifier: {
        IDn: "",
        ID: "",
        Name1: "",
        Name2: "",
        MinSelectedItems: "",
        MaxSelectedItems: "",
        Type: "Ingredient",
        SortOrder: "999",
      },
      modifierlist: null,
      sortable_ModGrp: null,
      sortable_ModItm: null,
      isSortableEnabled: false,

      dismessage: "",
      countdown: 0,
      confirmDialog: false,
      confirmDialog2: false,

      token: "",
      user_id: 0,

      departments: null,
      selected_departmentIDn: 0,
      categorys: null,
      selected_categoryIDn: 0,
      products: null,
      selected_productIDn: [],

      modifierItems: [],
      form_modifier_group: { 
        IDn: '',
        ID: '',
        Deleted: '',
        RobotKey: '',
        Name1: '',
        Name2: '',
        MinSelectedItems: '',
        MaxSelectedItems: '',
        Type: 'Ingredient',
        SortOrder: '999',
        Status: 1,
        AutoPopUp: 0,
        PrintMode: 0,
        Online: "true",
        Items: [],
      },
      form_modifier_item: {
        IDn: "",
        ID: "",
        RobotLv: "",
        Name1: "",
        Name2: "",
        ModifierGroupID: "",
        ModifierGroupIDn: "",
        ProductIDn: "",
        Price: "",
        SortOrder: "999",
        Status: "1",
      },
      type_options: [
        { text: "Ingredient", value: "Ingredient" },
        { text: "Command", value: "Command" },
        { text: "Number", value: "Number" },
        { text: "Combo", value: "Combo" },
      ],
      status_options: [
        { text: "Active", value: 1 },
        { text: "Inactive", value: 0 },
      ],
      popup_options: [
        { text: "Active", value: 1 },
        { text: "Inactive", value: 0 },
      ],
      print_mode_options: [
        { text: "Default", value: 0 },
        { text: "BoxApp", value: 1 },
      ],
      isSuper: false,
    };
  },
  watch :{
    'form_modifier_group.Items': function(val){
      if(val.length > 0){
        this.setSort_ModItm();
      }
    },
    deep: true
  },
  methods: {
    hasUtilityAuth,
    deleteRow(index){
      this.form_modifier_group.Items.splice(index, 1);
    },
    addRow() {
      var obj = JSON.parse(JSON.stringify(this.form_modifier_item));
      obj['RandomKey'] = Math.floor(Math.random()*10000000);
      this.form_modifier_group.Items.push(obj);
    },
    delete_modifier(row) {
      this.confirmDialog = true;
      for (var key in row) {
        this.form_modifier_group[key] = row[key];
      }
    },
    edit_modifier(row) {
      this.currpage = "edit";
      for (var key in row) {
        this.form_modifier_group[key] = row[key];
      }
      this.get_modifier_items();
      this.cur_opt = "update";
    },
    add_modifier() {
      this.currpage = "edit";
      this.resetForms();
      this.cur_opt = "add";
    },
    close_alert(countDownChanged) {
      this.countdown = countDownChanged;
      if (this.countdown == 0) {
        this.dismessage = "";
      }
    },
    filterByType(arg){
      if(arg === "Combo"){
        for(var i=this.form_modifier_group.Items.length-1; i>=0; i--){
          if(!this.form_modifier_group.Items[i].ProductIDn){
            this.deleteRow(i);
          }
        }
      } else {
        for(var i=this.form_modifier_group.Items.length-1; i>=0; i--){
          if(this.form_modifier_group.Items[i].ProductIDn){
            this.deleteRow(i);
          }
        }
      }
    },
    disableSort() {
      this.sortable_ModGrp.option('disabled', true);
      this.isSortableEnabled = false;
      this.update_sortOrder();
    },
    enableSort() {
      if (this.modifierlist.length <= 0) {
        return;
      }
      this.setSort_ModGrp();
      // this.sortable_ModGrp.option('disabled', false);
      this.isSortableEnabled = true;
    },
    setSort_ModGrp() {
      const el = this.$refs.dragTable.$el.querySelectorAll(
        ".el-table__body-wrapper > table > tbody"
      )[0];
      this.sortable_ModGrp = Sortable.create(el, {
        disabled: false,
        ghostClass: "sortable-ghost",
        setData: function (dataTransfer) {
          dataTransfer.setData("Text", "");
        },
        onEnd: (evt) => {
          // console.log("onEnd targetRow A:", evt.newIndex, evt.oldIndex, targetRow, this.form_modifier_group.Items);
          const targetRow = this.modifierlist.splice(evt.oldIndex, 1)[0];
          this.modifierlist.splice(evt.newIndex, 0, targetRow);
          // console.log("onEnd targetRow B:", this.form_modifier_group.Items);
        },
      });
    },
    setSort_ModItm() {
      const el = this.$refs.dragTable2.$el.querySelectorAll(
        ".el-table__body-wrapper > table > tbody"
      )[0];
      
      this.sortable_ModItm = Sortable.create(el, {
        disabled: false,
        handle: '.handle',
        ghostClass: "sortable-ghost",
        setData: function (dataTransfer) {
          dataTransfer.setData("Text", "");
        },
        onEnd: (evt) => {
          // console.log("onEnd targetRow A:", evt.newIndex, evt.oldIndex, targetRow, this.form_modifier_group.Items);
          const targetRow = this.form_modifier_group.Items.splice(evt.oldIndex, 1)[0];
          this.form_modifier_group.Items.splice(evt.newIndex, 0, targetRow);
          // console.log("onEnd targetRow B:", this.form_modifier_group.Items);
        },
      });
    },
    resetForms(){
      this.form_modifier_group = { 
        IDn: '',
        ID: '',
        Deleted: '',
        RobotKey: '',
        Name1: '',
        Name2: '',
        MinSelectedItems: '',
        MaxSelectedItems: '',
        Type: 'Ingredient',
        SortOrder: '999',
        Status: 1,
        AutoPopUp: 0,
        PrintMode: 0,
        Online: "true",
        Items: [],
      };
      this.form_modifier_item = {
        IDn: "",
        ID: "",
        RobotLv: "",
        Name1: "",
        Name2: "",
        ModifierGroupID: "",
        ModifierGroupIDn: "",
        ProductIDn: "",
        Price: "",
        SortOrder: "999",
        Status: "1",
      };
      this.modifierItems=[];
    },
    form_cancel() {
      this.currpage = "list";
      // clear sort status
      if(this.isSortableEnabled){
        this.sortable_ModGrp.option('disabled', true);
        this.isSortableEnabled = false;
        // refresh list data
        this.get_modifier_groups();
      }
    },
    confirmProductSelection() {
      for(var i=0; i<this.selected_productIDn.length; i++){
        var obj = {
          IDn: "",
          ID: "",
          Name1: "",
          Name2: "",
          ModifierGroupID: "",
          ModifierGroupIDn: "",
          ProductIDn: "",
          Price: "",
          SortOrder: "999",
          Status: "1",
        }
        for(var j=0; j<this.products.length; j++){
          if(this.selected_productIDn[i] == this.products[j].IDn){
            obj['Name1'] = this.products[j].Name1;
            obj['Name2'] = this.products[j].Name2;
            obj['Price'] = this.products[j].APrice;
            obj['ProductIDn'] = this.products[j].IDn;
            obj['RandomKey'] = Math.floor(Math.random()*10000000);
            this.form_modifier_group.Items.push(obj);
          }
        }
      }
      this.confirmDialog2 = false;
    },
    setModifierItems(){
      // Set status=0 for those that are removed from the form
      for(var i=0; i<this.modifierItems.length; i++){
        var deleted = true;
        for(var j=0; j<this.form_modifier_group.Items.length; j++){
          if(this.modifierItems[i].IDn == this.form_modifier_group.Items[j].IDn){
            deleted = false;
            break;
          }
        }
        if(deleted){
          this.modifierItems[i].Status = 0;
        }
      }
      // Add those that are inserted into the form
      for(var i=0; i<this.form_modifier_group.Items.length; i++){
        if(this.form_modifier_group.Items[i].IDn == "" && this.form_modifier_group.Items[i].Name1){
          this.form_modifier_group.Items[i].ModifierGroupIDn = this.form_modifier_group.IDn;
          this.modifierItems.push(this.form_modifier_group.Items[i]);
        }
      }
      // Set sort order
      // Insight: At this point, modifierItems has all the correct elements inside, but in
      // the wrong sort order. form_modifier_group.Items has the correct sort order, but 
      // missing those that are deleted.
      // Solution: Use form_modifier_group.Items as the base array. Set sortOrder = index + 1
      // Then, push those in modifierItems which Status=0, and set sortOrder = 999.
      var temp_arr = JSON.parse(JSON.stringify(this.form_modifier_group.Items));
      for(var i=0; i<temp_arr.length; i++){
        temp_arr[i].SortOrder = i+1;
      }
      for(var i=0; i<this.modifierItems.length; i++){
        if(this.modifierItems[i].Status == 0){
          this.modifierItems[i].SortOrder = 999;
          temp_arr.push(this.modifierItems[i]);
        }
      }
      this.modifierItems = temp_arr;
    },
    set_modifierlist(list){
      // convert Online
      for(var i=0; i<list.length; i++){
        if(list[i].Online == 1) {
          list[i].Online = "true";
        } else {
          list[i].Online = "false";
        }
      }
      this.modifierlist = list;
    },
    setRandomKey(){
      for(var i=0; i<this.form_modifier_group.Items.length; i++){
        if(!this.form_modifier_group.Items[i].RandomKey){
          this.form_modifier_group.Items[i].RandomKey = Math.floor(Math.random()*10000000);
        }
      }
    },
    setProductOptionsList(products) {
      this.product_options = [];
      for(let i=0; i<products.length; i++){
        var obj = {
          text: products[i].Name1 + "-" + products[i].Name2,
          value: products[i].IDn
        }
        this.product_options.push(obj);
      }
    },
    setDefaultCategory(categorys) {
      if(Array.isArray(categorys) || !categorys.length) {
        this.selected_categoryIDn = categorys[0].IDn;
        this.get_product_list();
      }
    },
    async deleteConfirmed() {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("IDn", this.form_modifier_group.IDn);
      var that = this;
      this.confirmDialog = false
      
      axios({
        method: "post",
        url: "/Api/Web/modifier/delete",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.dismessage = that.$t("Data Updated Successfully");
              that.countdown = 3;
              that.currpage = "list";
              that.get_modifier_groups();
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async change_online(row){
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("IDn", row.IDn);
      bodyFormData.append("Online", row.Online);

      var that = this;

      axios({
        method: "post",
        url: "/Api/Web/modifier/change_online",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              // console.log("rt", rt);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async change_autoPopUp(row) {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("IDn", row.IDn);
      bodyFormData.append("AutoPopUp", row.AutoPopUp);

      var that = this;

      axios({
        method: "post",
        url: "/Api/Web/modifier/change_autoPopUp",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              // console.log("rt", rt);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async update_sortOrder() {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      var orderlist = [];
      for (var i = 0; i < this.modifierlist.length; i++){
        orderlist.push({IDn:this.modifierlist[i].IDn, SortOrder:i+1});
      }
      bodyFormData.append("SortOrderList", JSON.stringify(orderlist));

      var that = this;

      axios({
        method: "post",
        url: "/Api/Web/modifier/sort_order",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.set_modifierlist(rt.data.modifiers);
              // that.$router.go();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async form_submit(event) {
      if (event) {
        event.preventDefault();
      }
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("opt", this.cur_opt);
      var that = this;
      for (var key in this.form_modifier_group) {
        if (key != "Items"){
          bodyFormData.append(key, this.form_modifier_group[key]);
        }
      }
      this.setModifierItems();
      bodyFormData.append("modifierItems", JSON.stringify(this.modifierItems));

      axios({
        method: "post",
        url: "/Api/Web/modifier/edit",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              //that.$router.push("/store");
              that.$router.go();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async get_category_list() {
      this.selected_categoryIDn = 0;
      if (this.selected_departmentIDn == 0) {
        return;
      }
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("DepartmentIDn", this.selected_departmentIDn);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Category/search",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.product_options = [];
              that.categorys = rt.data.categorys;
              that.setDefaultCategory(rt.data.categorys);
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async get_product_list() {
      this.selected_productIDn = [];
      if (this.selected_categoryIDn == 0) {
        return;
      }
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("CategoryIDn", this.selected_categoryIDn);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Product/search",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.products = rt.data.products;
              that.setProductOptionsList(rt.data.products);
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async get_modifier_items() {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("ModifierGroupIDn", this.form_modifier_group.IDn);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/modifier_item",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.form_modifier_group.Items = rt.data.modifier_items;
              that.setRandomKey();

              that.modifierItems = JSON.parse(JSON.stringify(rt.data.modifier_items));
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async get_modifier_groups() {
      // this will be called only after form is valid. You can do api call here to login
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/modifier",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.set_modifierlist(rt.data.modifiers);
              that.departments = rt.data.departments;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async init_data() {
      this.token = getStore("token");
      this.user_id = getStore("user_id");
      this.isSuper = isSuper();
      if (!this.token || !this.user_id) {
        this.$router.push("/login");
        return;
      }
      return this.get_modifier_groups();
    },
  },
  mounted() {
    this.init_data();
    this.windowHeight = window.innerHeight;
  },
};
</script>
<style lang="scss" scoped>
/deep/ .content {
  padding-top: 80px;
}
/* Lisa modify for dragging sort 2022/01/18 ---- start----- */
/deep/ .handshape{
  cursor: pointer;
}
/* Lisa modify for dragging sort 2022/01/18 ---- end----- */
/deep/ .my-header {
  background: #00e1ff !important;
  color: rgb(17, 16, 16) !important;
}
/deep/ .my-header2 {
  background: #e8e6f0 !important;
  color: rgb(17, 16, 16) !important;
}
/deep/ .el-table td, .el-table th {
  text-align: center;
  font-size: 16px !important;
}
/deep/ .el-table::before {
  display: none !important;
}
/deep/ .card-header {
  padding: 0.5rem 1.5rem !important;
}
/deep/ .list-group-item {
  padding: 0.5rem 1rem !important;
}
/deep/ .handle {
  display: inline-block;
  width: 16px;
  height: 8px;
}
/deep/ .handle, .handle::before {
  background-image: radial-gradient(black 40%, transparent 40%);
  background-size: 4px 4px;
  background-position: 0 100%;
  background-repeat: repeat-x;
  cursor: move;
}
/deep/ .handle::after {
  content: '';
  display: block;
  width: 100%;
  height: 33%;
}
/deep/ .btn-secondary:not(:disabled):not(.disabled).active {
  background-color: #68BBE3;
}
</style>
